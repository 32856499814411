$font-family-sans-serif: Muli, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$font-size-xs: 0.75rem;
$font-size-base: 1rem;
$font-size-lg: 1.25rem;
$font-size-sm: 0.75rem;
$font-size-h4: 1.25rem;

$gold: #00C350 !default;
$blue: #21215A !default;
$cyan: #0082F3 !default;
$red: #B9525A !default;
$orange: #ED7250 !default;
$yellow: #F3984B !default;
$purple: #645892 !default;
$green: #51b87c !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;

$black: #000000;
$white: #ffffff;
$white-transparent: rgba(255, 255, 255, 0.75);

$alt-white: $gray-100;
$alt-white-dark: $gray-200;
$gray-lighter: $gray-300;
$gray-light: $gray-500;
$gray: $gray-600;
$gray-dark-lighten: $gray-700;
$gray-darken: $gray-800;
$gray-dark: $gray-900;

$green-light: lighten($green, 20);
$green-dark: darken($green, 20);
$green-darken: darken($green, 40);

$blue-darken: #1aa3e8;
$purple-darken: #ba2fc6;

$primary: $gold !default;
$brand-primary: $gold;
$secondary: $cyan !default;
$success: $gold !default;
$info: $blue !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-300 !default;
$dark: $gray-800 !default;

$border-radius: .123rem !default;
$border-radius-lg: .15rem !default;
$border-radius-sm: .1rem !default;

$enable-caret: true !default;
$enable-rounded: true !default;
$enable-shadows: true !default;
$enable-gradients: false !default;
$enable-transitions: true !default;
$enable-prefers-reduced-motion-media-query: true !default;
$enable-grid-classes: true !default;
$enable-pointer-cursor-for-buttons: true !default;
$enable-rfs: false !default;
$enable-validation-icons: true !default;
$enable-deprecation-messages: true !default;

$arrow-up: $red;
$arrow-down: $green;
$arrow-right: $cyan;
$sidebar-width: 270px;
$seachPanel-width: 480px;
$elec: #31acea;
$gas: #00c350ab;
$spacing: 15;

:export {
  fontFamily: $font-family-sans-serif;

  blue: $blue;
  gold: $gold;
  red: $red;
  orange: $orange;
  yellow: $yellow;
  cyan: $cyan;
  purple: $purple;
  green: $green;

  gray100: $gray-100;
  gray200: $gray-200;
  gray300: $gray-300;
  gray400: $gray-400;
  gray500: $gray-500;
  gray600: $gray-600;
  gray700: $gray-700;
  gray800: $gray-800;
  gray900: $gray-900;

  primary: $primary;
  secondary: $secondary;
  success: $success;
  info: $info;
  warning: $warning;
  danger: $danger;
  light: $light;
  dark: $dark;

  elec: $elec;
  gas: $gas;
  spacing: $spacing
}
