@font-face {
  font-family: 'Merlo';
  src: url('../fonts/merlo/merlo-neue-round-light-webfont.woff2') format('woff2'),
       url('../fonts/merlo/merlo-neue-round-light-webfont.woff') format('woff');
}

@keyframes glow {
  80% {
    opacity: 0.4;
    transform: scale3d(1, 1, 1);
  }

  100% {
    opacity: 0;
    transform: scale3d(1.15, 1.75, 1);
  }
}

.sidebar-col {
  max-width: $sidebar-width;
  flex: 1;
  z-index: 2000;
  width: $sidebar-width;
}

.sidebar {
  background: white;  width: 100%;
  height: 100vh;
  color: $white;
  overflow: hidden;
  position: fixed;
  width: $sidebar-width;

  ul {
    padding: 0;
    list-style: none;
  }

  .logo {
    padding: 22px 55px 28px;
    display: block;
  }

  .nav-tabs {
    font-size: $font-size-base;
    border-top: 1px solid $gray;
    border-bottom: 1px solid $gray;

    .nav-item {
      &:last-child {
        border-left: 1px solid $gray;
      }
    }

    .nav-link {
      background: none;
      border-radius: 0;
      border: none;
      text-align: center;
      padding: 12px 0 16px;
      color: $gray-light;
      cursor: pointer;

      &.active {
        color: $white;

        &:after {
          content: "";
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 5px;
          background: $green;
        }
      }

      .badge {
        margin-left: 5px;
        position: relative;
        top: -1px;
        font-size: 11px;
        font-weight: normal;
        height: 18px;
        text-align: center;
        line-height: 18px;
        width: auto;
        padding: 0 0.5em;
        border-radius: 10px;
      }
    }
  }

  .current-selection-tab {
    .current-selection-wrapper {
      display: flex;
      flex-direction: column;
      height: calc(100vh - 250px);

      .current-selection-header,
      .current-selection-footer,
      .current-selection-sort {
        flex-grow: 0;
        flex-shrink: 0;
      }

      .current-selection-content {
        overflow-y: auto;
      }

      .btn-link {
        box-shadow: none;
        outline: none;
        cursor: pointer;
      }
    }

    .toggle-panel {
      margin: 20px 0 20px;
      cursor: pointer;
      position: relative;

      &.glow {
        margin-bottom: 25px;

        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background: inherit;
          z-index: -1;
          opacity: 0.4;
          transform: scale3d(1, 1, 1);
          animation: glow 4s infinite;
        }
      }
    }

    .current-selection-content {
      .current-selection-label {
        padding: 20px 0 10px;
        display: inline-block;
      }

      .current-selection-label {
        .btn-group {
          margin-right: -4px;

          .btn {
            background: none;
            padding: 0 4px;
            color: $gray-light;
            border: none;
            opacity: 0;
            cursor: pointer;
            box-shadow: none;
            font-size: 1em;

            &:active,
            &:focus {
              border: none;
            }

            i {
              margin-right: 0.25em;

              &.icon-cross {
                font-size: 0.9em;
              }
            }
          }
        }

        &:hover {
          .btn-group {
            .btn {
              opacity: 1;
            }
          }
        }
      }

      .current-selection-item-list:hover {
        background: #0082F3;
      }

      .current-selection-item-list {
        //background: #0082F3;
        margin-left: -15px;
        margin-right: -15px;
        padding: 6px 5px 6px 10px;

        li {
          padding: 6px 0;
          transition: color 0.3s ease;

          span {
            overflow: hidden;
            text-overflow: ellipsis;
          }

          i {
            transition: color 0.3s ease;
            margin-right: 0.75em;
            position: relative;
            top: -1px;

            &:before {
              vertical-align: sub;
            }

            &.icon-elec {
              color: $elec;
            }

            &.icon-gaz {
              color: $gas;
            }
          }

          .btn-group {
            margin-right: -4px;

            .btn:not(.zoom) {
              opacity: 0;
            }

            .btn {
              background: none;
              padding: 0 4px;
              color: $gray-light;
              border: none;
              cursor: pointer;
              box-shadow: none;
              font-size: 1em;

              &:active,
              &:focus {
                border: none;
              }

              i {
                margin-right: 0.25em;

                &.icon-cross {
                  font-size: 0.9em;
                }
              }

              &.activated {
                color: $yellow;
                font-weight: bold;
                opacity: 1;
              }
            }
          }

          &.selected-contract:not(.visible) {
            color: $gray-light;

            i.icon-elec,
            i.icon-gaz {
              color: $gray-light;
            }

            .btn-group {
              .btn:last-child {
                opacity: 1;
              }
            }
          }

          &.selected-contract:hover {
            .btn-group {
              .btn {
                opacity: 1;
              }
            }
          }

          &.selected-contract.zoom {
            color: $yellow;
            font-weight: bold;
            opacity: 1 !important;

            i {
              &.selected-activity {
                color: $yellow !important;
              }
            }
          }
        }
      }

      a {
        color: inherit;

        &:hover {
          text-decoration: none;
          color:  $green;
        }
      }
    }

    .current-selection-footer {
      border-top: 1px solid $gray;
      padding-top: 10px;

      .btn-link {
        padding: 10px 0;
        flex-grow: 1;
        color: $white;

        span {
          width: 100%;
          display: block;
          position: relative;
          font-size: $font-size-sm;
        }

        &:first-child {
          span:after {
            content: "";
            display: block;
            position: absolute;
            right: 0;
            top: 10%;
            bottom: 5%;
            width: 1px;
            background: $gray;
          }
        }
      }
    }
  }

  .favorites-tab {
    padding-top: 30px;

    .btn-link {
      padding-left: 0;
      color: $white;
      box-shadow: none;
      cursor: pointer;
      max-width: 90%;
      width: 100%;
      text-align: left;
      text-overflow: ellipsis;
    }

    div {
      .btn-secondary {
        background: none;
        padding: 0 4px;
        color: $gray-light;
        border: none;
        opacity: 0;
        cursor: pointer;
        box-shadow: none;

        &:active,
        &:focus {
          border: none;
        }
      }

      &:hover {
        .btn {
          opacity: 1;
        }
      }
    }
  }
}
