.menu-user {
  align-self: center;
}

.topNav {
  padding: 0 15px;
  width: 100%;
  z-index: 500;
  background-color:white !important;


  .nav-link {
    font-size: 1rem;
    padding: 0 15px !important;
    font-weight: normal;
    color: black;

    @media (max-width: 1500px) {
      font-size: .9rem;
    }

    i {
      margin-right: 3px;
    }

    &:hover {
      color: black !important;
    }

    span {
      display: block;
      padding: 26px 0 !important;
      position: relative;

      .badge {
        width: 1.5em;
        height: 1.5em;
        line-height: 1.4em;
        display: inline-block;
        vertical-align: middle;
        padding: 0 !important;
        margin-left: 0.5em;
        border-radius: 50%;
        background: $red;
        position: relative;
        top: -1px;
      }
    }

    &.active {
      color: black !important;

      > span {
        &:after {
          content: "";
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 5px;
          background: darken(#0082F3, 12);
        }
      }
    }
  }

  .btn-link {
    color: $white;
    font-size: $font-size-lg;
    text-decoration: none;
    cursor: pointer;

    .icon-smallarrow-down {
      font-size: $font-size-xs;
      margin-left: 6px;
    }
  }
}
