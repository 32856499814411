.form-check.checked {
  background-color: $cyan;
  label {
    color: #fff !important;
  }
}

.quotation-popover {
  .popover-inner {
    width: 700px;
  }

  .quotation-selection {
    input[type="radio"], input[type="checkbox"] {
      display: block;
      margin: 4px auto;
    }
  }

  .reactable-pagination {
    a {
      display: inline-block;
      padding: 0 16px;

      &.reactable-current-page {
        font-weight: 700;
      }
    }
  }
}

.alert-graph {
  min-height: 500px;
}

.alert-popover,
.transaction-popover {
  label {
    font-size: 14px !important;
  }

  .radio-input {
    label {
      margin-right: 1.5em;
      cursor: pointer;

      &:before {
        content: "";
        display: block;
        position: relative;
        margin-right: 0.5em;
        height: 1em;
        width: 1em;
        border-radius: 100%;
        top: -1px;
        border: 1px solid $gray-lighter;
      }
    }

    input {
      display: none;

      &:checked + label:before {
        background: $green;
        box-shadow: inset 0 0 0 2px $white;
      }
    }
  }

  .top-pad {
    padding-top: 8px;
  }
}

.hedging-popover-target {
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  grid-auto-rows: 42px;
  grid-gap: 5px;

  .form-check {
    border-left: solid 1px #d7dee4;
  }
}

.hedging-plan-length-input {
  display: inline-block;
  margin: 0;

  input {
    padding: 0.475rem 0.75rem;
    border-radius: 0;

    // border-left: none
    height: 43px;
    margin-bottom: -14px;
    width: 6rem;
  }
}

.hedging-profile-popover {
  .error {
    color: $red;
  }

  .form-check {
    padding-left: 0;
  }
}

.currentMonth {
  background-color: $gray-darken;
  color: white;
}

#hedgingPlans {
  .title {
    font-size: 1rem;
    font-weight: 500;
  }

  .add-hedging-plan {
    margin-top: 20px;

    .add-hedging-plan-progress {
      font-size: 1rem;
    }
  }

  .delete-hedging-plan {
    z-index: 100;
    position: absolute;
    width: 100%;

    i {
      margin: 0 0 0 auto;
      font-size: 1rem;
      cursor: pointer;

      &:hover {
        color: $primary;
      }
    }
  }

  .hedgingPlan {
    position: relative;
    padding: 20px 0 20px;
    border-bottom: solid 1px $gray-lighter;

    &:last-child {
      border-bottom: 0;
    }

    .form-check {
      padding-left: 0;
      border-left: solid 1px #d7dee4;
      margin: 0 0 -2px -1px;
    }

    .choice-group {
      margin-top: 1rem;
    }
  }

  .add-hedging-plan-progress {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .hedging-plan-graph {
    margin-top: 1rem;
  }
}

.strategy-contract-board {
  margin-top: 30px;

  i {
    &.icon-elec {
      color: $elec;
    }

    &.icon-gaz {
      color: $gas;
    }
  }
}

.section-ninety {
  height: 92%;
}
