button.btn-table.btn.btn-secondary.alert-icon {
  background: none !important;
  text-align: center;

  > i {
    color: $gray;
    margin-right: 0;
  }
}

.accordion {
  padding: 0 !important;

  .accordion-item {
    h4 {
      font-size: $font-size-base;
      text-transform: uppercase;
      color: $gray-light;
      margin-bottom: 10px;
    }

    hr {
      margin: 5px -20px 15px;
    }

    .accordion-btn {
      border: none;
      background: none;
      border-radius: 0;
      width: 100%;
      text-align: left;
      font-weight: bold;
      color: $gray;
      box-shadow: none;
      outline: none;
      cursor: pointer;
      padding: 10px 12px 9px;
      align-items: center;

      &:hover {
        background: $gray-300;
      }

      &:active {
        color: unset;
      }

      > i {
        margin-right: 18px;
        position: relative;
        top: -1px;

        &.icon-elec {
          color: $elec;
        }

        &.icon-gaz {
          color: $gas;
        }

        &[class*='icon-collapse'] {
          margin-right: 0;
          font-size: $font-size-lg;
          color: $gray-light;
        }
      }

      small {
        font-size: 1em;
        position: relative;
        padding-left: 9px;
        margin-left: 9px;
        font-weight: 100;
        background: none !important;

        &:before {
          display: none;
          background: none !important;
        }


      }
    }

    .badge {
      background: $alt-white;
      border-radius: 0;
      font-size: 1.2rem;
      text-transform: uppercase;
      padding: 0.15em 0.3em;
      margin-bottom: 15px;
    }

    .table {
      th, td {
        font-weight: normal;
        padding: 0.6em 1.3em;
      }

      &.table-bordered {
        border: none;

        td {
          border-bottom: 1px solid $gray-lighter;
          vertical-align: middle;

          &:first-child {
            font-weight: bold;
          }

          &:last-child {
            padding-right: 0;
          }
        }
      }

      &.table-striped {
        th {
          background: $gray-light;
          color: $white;

          &:after {
            position: absolute;
            top: 50%;
            margin-top: -0.3em;
            right: 6px;
          }
        }

        th, td {
          padding-top: 0.4em;
          padding-bottom: 0.4em;
        }
      }

      .form-control {
        display: inline-block;
        width: auto;
        font-size: $font-size-sm;
      }
    }

    &:nth-child(even) {
      .accordion-btn {
        background: $gray-200;

        &:hover {
          background: $gray-300;
        }
      }
    }

    &.open {
      .accordion-btn {
        color: $white;
        > i {
          color: $white;
        }
        small:before {
          background: $white;
        }
      }

      &.elec {
        .accordion-btn {
          background: $elec;
          &:hover {
            background: $elec;
          }
        }
        .badge {
          color: $elec;
        }
      }

      &.gas {
        .accordion-btn {
          background: $gas;

          &:hover {
            background: $gas;
          }
        }

        .badge {
          color: $gas;
        }
      }
    }

    .collapse, .collapsing {
      padding: 0;
      border-bottom: 1px solid $alt-white;

      > div {
        padding: 25px 20px 15px;
      }

      .lot-infos {
        margin-bottom: 10px;
        margin-top: 10px;
      }
    }
  }
}
